import { lazy } from 'react'
import withSuspense from './withSuspense'

/* eslint-disable */
const ActivateAccountPage            = lazy(() => import('../pages/ActivateAccountPage')),
      AdminPanelPage                 = lazy(() => import('../pages/admin/AdminPanelPage')),
      AffiliateJoinPage              = lazy(() => import('../pages/affiliate/AffiliateJoinPage')),
      AffiliateViewSubsPage          = lazy(() => import('../pages/affiliate/AffiliateViewSubsPage')),
      AskATeacherPage                = lazy(() => import('../pages/ask_a_teacher/AskATeacherPage')),
      AskATeacherQuestionPage        = lazy(() => import('../pages/ask_a_teacher/AskATeacherQuestionPage')),
      BadgesPage                     = lazy(() => import('../pages/BadgesPage')),
      BlogEditorPage                 = lazy(() => import('../pages/blog/BlogEditorPage')),
      BlogHomePage                   = lazy(() => import('../pages/blog/BlogHomePage')),
      BlogPage                       = lazy(() => import('../pages/blog/BlogPage')),
      BookSessionsPage               = lazy(() => import('../pages/sessions/BookSessionsPage')),
      BookStorePage                  = lazy(() => import('../pages/BookStorePage')),
      BugReportPage                  = lazy(() => import('../pages/BugReportPage')),
      BugReportDetailsPage           = lazy(() => import('../pages/admin/BugReportDetailsPage')),
      BulletinBoardPage              = lazy(() => import('../pages/bulletin_board/BulletinBoardPage')),
      ChangeEmailPage                = lazy(() => import('../pages/ChangeEmailPage')),
      ChangePasswordPage             = lazy(() => import('../pages/ChangePasswordPage')),
      ClaimAccountPage               = lazy(() => import('../pages/ClaimAccountPage')),
      ConjugationGamePage            = lazy(() => import('../pages/games/conjugation_game/ConjugationGamePage')),
      CourseMainPage                 = lazy(() => import('../pages/CourseMainPage')),
      CourseVideoPlayerPage          = lazy(() => import('../pages/CourseVideoPlayerPage')),
      CoursePreviewPage              = lazy(() => import('../fz_courses/admin/CoursePreviewPage')),
      CoursesPreviewPage             = lazy(() => import('../fz_courses/admin/CoursesPreviewPage')),
      DesdeCeroLandingPage           = lazy(() => import('../pages/DesdeCeroLandingPage')),
      DevPlayground                  = lazy(() => import('../pages/DevPlayground')),
      DictionaryPage                 = lazy(() => import('../pages/dictionary/DictionaryPage')),
      EarlySupporterThankYouPage     = lazy(() => import('../pages/EarlySupporterThankYouPage')),
      FAQPage                        = lazy(() => import('../pages/FAQPage')),
      FlashCardsPage                 = lazy(() => import('../pages/games/flash_cards/FlashCardsPage')),
      FZLivePage                     = lazy(() => import('../pages/fz_live/FZLivePage')),
      GamesPage                      = lazy(() => import('../pages/games/GamesPage')),
      HomePage                       = lazy(() => import('../pages/homeroom/HomePage')),
      Homeroom                       = lazy(() => import('../pages/homeroom/Homeroom')),
      InteractiveVideoPage           = lazy(() => import('../pages/InteractiveVideoPage')),
      KanaGamePage                   = lazy(() => import('../pages/games/kana_game/KanaGamePage')),
      KanjiClass                     = lazy(() => import('../pages/games/kanji_class/KanjiClass')),
      KanjiDrawPage                  = lazy(() => import('../pages/draw/KanjiDrawPage')),
      KansaiCoursePage               = lazy(() => import('../pages/courses/KansaiCoursePage')),
      KansaiCourseBuyPage            = lazy(() => import('../pages/courses/KansaiCourseBuyPage')),
      KoreanRedirectPage             = lazy(() => import('../pages/KoreanRedirectPage')),
      LandingPage                    = lazy(() => import('../pages/landing_page/v3/LandingPage')),
      LessonOverviewPage             = lazy(() => import('../fz_courses/admin/editor/LessonOverviewPage')),
      LoginPage                      = lazy(() => import('../pages/LoginPage')),
      MyAccountPage                  = lazy(() => import('../pages/MyAccountPage')),
      MyNotebookPage                 = lazy(() => import('../pages/MyNotebookPage')),
      NotificationsPage              = lazy(() => import('../pages/notifications/NotificationsPage')),
      PostersPage                    = lazy(() => import('../pages/hirakata_poster/PostersPage')),
      PremiumMembershipSubscribePage = lazy(() => import('../pages/PremiumMembershipSubscribePage')),
      PrivacyPolicyPage              = lazy(() => import('../pages/PrivacyPolicyPage')),
      PublicBugPage                  = lazy(() => import('../pages/ask_a_teacher/views/open_bugs/PublicBugItem')),
      RegisterPage                   = lazy(() => import('../pages/RegisterPage')),
      RequestPasswordChangePage      = lazy(() => import('../pages/RequestPasswordChangePage')),
      ResendConfirmationPage         = lazy(() => import('../pages/ResendConfirmationPage')),
      SearchPage                     = lazy(() => import('../pages/SearchPage')),
      SimilarKanjiGame               = lazy(() => import('../pages/games/similar_kanji_game/SimilarKanjiGame')),
      StorePage                      = lazy(() => import('../pages/StorePage')),
      SubLessonPage                  = lazy(() => import('../fz_courses/sublessonpage/SublessonPage')),
      TeacherPanelPage               = lazy(() => import('../pages/teacher/TeacherPanelPage')),
      TermsOfUse                     = lazy(() => import('../pages/TermsOfUse')),
      ThankYouPage                   = lazy(() => import('../pages/ThankYouPage')),
      ThankYouPremiumPage            = lazy(() => import('../pages/ThankYouPremiumPage')),
      ToolsPage                      = lazy(() => import('../pages/tools/ToolsPage')),
      UpdateNotesPage                = lazy(() => import('../pages/updates/UpdateNotesPage')),
      VaultMembershipPage            = lazy(() => import('../pages/vault/VaultMembershipPage')),
      VerbLab                        = lazy(() => import('../pages/games/verb_lab/VerbLab')),
      VideoVaultAdminPage            = lazy(() => import('../pages/vault/VideoVaultAdminPage')),
      VideoVaultCategoryPage         = lazy(() => import('../pages/vault/VideoVaultCategoryPage')),
      VideoVaultPage                 = lazy(() => import('../pages/vault/VideoVaultPage')),
      VideoVaultEdit                 = lazy(() => import('../pages/vault/VideoVaultEdit')),
      VideoVaultDelete               = lazy(() => import('../pages/vault/VideoVaultDelete')),
      VideoVaultVideoPage            = lazy(() => import('../pages/vault/VideoVaultVideoPage')),
      ViewCoursePage                 = lazy(() => import('../pages/ViewCoursePage')),
      UserProfile                    = lazy(() => import('../pages/UserProfile')),
      WordCafe                       = lazy(() => import('../pages/games/word_cafe/WordCafe'))

export const suspendedActivateAccountPage            = withSuspense(ActivateAccountPage),
             suspendedAdminPanelPage                 = withSuspense(AdminPanelPage),
             suspendedAffiliateJoinPage              = withSuspense(AffiliateJoinPage),
             suspendedAffiliateViewSubsPage          = withSuspense(AffiliateViewSubsPage),
             suspendedAskATeacherPage                = withSuspense(AskATeacherPage),
             suspendedAskATeacherQuestionPage        = withSuspense(AskATeacherQuestionPage),
             suspendedBadgesPage                     = withSuspense(BadgesPage),
             suspendedBlogEditorPage                 = withSuspense(BlogEditorPage),
             suspendedBlogHomePage                   = withSuspense(BlogHomePage),
             suspendedBlogPage                       = withSuspense(BlogPage),
             suspendedBookSessionsPage               = withSuspense(BookSessionsPage),
             suspendedBookStorePage                  = withSuspense(BookStorePage),
             suspendedBugReportPage                  = withSuspense(BugReportPage),
             suspendedBugReportDetailsPage           = withSuspense(BugReportDetailsPage),
             suspendedBulletinBoardPage              = withSuspense(BulletinBoardPage),
             suspendedChangeEmailPage                = withSuspense(ChangeEmailPage),
             suspendedChangePasswordPage             = withSuspense(ChangePasswordPage),
             suspendedClaimAccountPage               = withSuspense(ClaimAccountPage),
             suspendedConjugationGamePage            = withSuspense(ConjugationGamePage),
             suspendedCourseMainPage                 = withSuspense(CourseMainPage),
             suspendedCourseVideoPlayerPage          = withSuspense(CourseVideoPlayerPage),
             suspendedCoursePreviewPage              = withSuspense(CoursePreviewPage),
             suspendedCoursesPreviewPage             = withSuspense(CoursesPreviewPage),
             suspendedDesdeCeroLandingPage           = withSuspense(DesdeCeroLandingPage),
             suspendedDevPlayground                  = withSuspense(DevPlayground),
             suspendedDictionaryPage                 = withSuspense(DictionaryPage),
             suspendedEarlySupporterThankYouPage     = withSuspense(EarlySupporterThankYouPage),
             suspendedFAQPage                        = withSuspense(FAQPage),
             suspendedFlashCardsPage                 = withSuspense(FlashCardsPage),
             suspendedFZLivePage                     = withSuspense(FZLivePage),
             suspendedGamesPage                      = withSuspense(GamesPage),
             suspendedHomePage                       = withSuspense(HomePage),
             suspendedHomeroom                       = withSuspense(Homeroom),
             suspendedInteractiveVideoPage           = withSuspense(InteractiveVideoPage),
             suspendedKanaGamePage                   = withSuspense(KanaGamePage),
             suspendedKanjiClass                     = withSuspense(KanjiClass),
             suspendedKanjiDrawPage                  = withSuspense(KanjiDrawPage),
             suspendedKansaiCoursePage               = withSuspense(KansaiCoursePage),
             suspendedKansaiCourseBuyPage            = withSuspense(KansaiCourseBuyPage),
             suspendedKoreanRedirectPage             = withSuspense(KoreanRedirectPage),
             suspendedLandingPage                    = withSuspense(LandingPage),
             suspendedLessonOverviewPage             = withSuspense(LessonOverviewPage),
             suspendedLoginPage                      = withSuspense(LoginPage),
             suspendedMyAccountPage                  = withSuspense(MyAccountPage),
             suspendedMyNotebookPage                 = withSuspense(MyNotebookPage),
             suspendedNotificationsPage              = withSuspense(NotificationsPage),
             suspendedPostersPage                    = withSuspense(PostersPage),
             suspendedPremiumMembershipSubscribePage = withSuspense(PremiumMembershipSubscribePage),
             suspendedPrivacyPolicyPage              = withSuspense(PrivacyPolicyPage),
             suspendedPublicBugPage                  = withSuspense(PublicBugPage),
             suspendedRegisterPage                   = withSuspense(RegisterPage),
             suspendedRequestPasswordChangePage      = withSuspense(RequestPasswordChangePage),
             suspendedResendConfirmationPage         = withSuspense(ResendConfirmationPage),
             suspendedSearchPage                     = withSuspense(SearchPage),
             suspendedSimilarKanjiPage               = withSuspense(SimilarKanjiGame),
             suspendedStorePage                      = withSuspense(StorePage),
             suspendedSubLessonPage                  = withSuspense(SubLessonPage),
             suspendedTeacherPanelPage               = withSuspense(TeacherPanelPage),
             suspendedTermsOfUse                     = withSuspense(TermsOfUse),
             suspendedThankYouPage                   = withSuspense(ThankYouPage),
             suspendedThankYouPremiumPage            = withSuspense(ThankYouPremiumPage),
             suspendedToolsPage                      = withSuspense(ToolsPage),
             suspendedUpdatesPage                    = withSuspense(UpdateNotesPage),
             suspendedVaultMembershipPage            = withSuspense(VaultMembershipPage),
             suspendedVerbLab                        = withSuspense(VerbLab),
             suspendedVideoVaultAdminPage            = withSuspense(VideoVaultAdminPage),
             suspendedVideoVaultCategoryPage         = withSuspense(VideoVaultCategoryPage),
             suspendedVideoVaultPage                 = withSuspense(VideoVaultPage),
             suspendedVideoVaultEdit                 = withSuspense(VideoVaultEdit),
             suspendedVideoVaultDelete               = withSuspense(VideoVaultDelete),
             suspendedVideoVaultVideoPage            = withSuspense(VideoVaultVideoPage),
             suspendedViewCoursePage                 = withSuspense(ViewCoursePage),
             suspendedUserProfile                    = withSuspense(UserProfile),
             suspendedWordCafe                       = withSuspense(WordCafe)
/* eslint-enable */
